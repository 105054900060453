import axios from "axios";
import config from "../config/config";

export const getGeoFencingStatus = async (property_id, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/openApis/getGeoFencingStatus`,
      params: {
        property_id,
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//check who does this property belong to
export const getPropertyOwner = async (property_id, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/openApis/getPropertyOwner`,
      params: {
        property_id,
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

//post to geolocations to database
export const postGeoCodes = async (property_id, geo_codes, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/openApis/postGeoCodes`,
      data: {
        property_id,
        geo_codes,
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const uploadImages = async (
  property_id,
  userDetails,
  mediaUrls,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/auth/uploadPropertyPics`,
      data: {
        user_property_id: property_id,
        email: userDetails.email,
        user_id: userDetails.id,
        media_url: mediaUrls,
        media_type: 1,
        status: 1,
        status_history: {},
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const uploadImage = async (formData, callback) => {
  try {
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    let response = await axios({
      method: "POST",
      url: `${config.server.url}/openApis/upload`,
      data: formData,
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
