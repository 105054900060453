import React, { useState, useRef, useEffect } from "react";
import "./UploadInitialImages.css";
import { uploadFile } from "react-s3";
import { uploadImage, uploadImages } from "../modules/geofencing.module";
import wp from "../wp.png";

function UploadInitialImages({ setPageRoute, propId, userDetails }) {
  const fileInput = useRef();
  const [mediaUrls, setMediaUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  async function showPreview(event) {
    let val = event.target.attributes[4].nodeValue;
    if (event.target.files.length > 0) {
      let src = URL.createObjectURL(event.target.files[0]);
      let fileName = event.target.files[0].name;

      let idxDot = fileName.lastIndexOf(".") + 1;
      let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        let preview = document.getElementById(`file-ip-${val}-preview`);

        preview.src = src;
        preview.style.display = "block";

        const newFile = new File(
          [event.target.files[0]],
          Math.floor(Math.random() * 1000000) + Date.now() + `.${extFile}`
        );
        alert(
          "Image is being processed, meanwhile switch to other position to take the next picture."
        );
        setLoading(true);

        alert(event.target.files);
        alert(event.target.file);

        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        await uploadImage(formData, (err, result) => {
          if (result) {
            alert(result.data);
            alert(result.data?.Location);
            let media_urls = [...mediaUrls];
            media_urls[val - 1] = result.data.Location;
            setMediaUrls(media_urls);
            setLoading(false);
          } else {
            console.error(err, ">>ERROR");
            alert(err);
            alert(err?.message);
            setLoading(false);
          }
        });
        // uploadFile(newFile, config)
        //   .then((data) => {
        //     let media_urls = [...mediaUrls];
        //     media_urls[val - 1] = data.location;
        //     setMediaUrls(media_urls);
        //     setLoading(false);
        //   })
        //   .catch((err) => {
        //     console.error(err, ">>ERROR");
        //     setLoading(false);
        //   });
      } else {
        alert("Only jpg/jpeg and png files are allowed!");
        setLoading(false);
      }
    }
  }

  const UploadInitialImages = () => {
    try {
      if (mediaUrls.filter((item) => item !== undefined).length < 3) {
        alert("Please capture atleast 3 pictures.");
        return;
      }
      setIsDisabled(true);
      if (mediaUrls.filter((item) => item !== undefined).length > 0) {
        uploadImages(
          propId,
          userDetails,
          mediaUrls.filter((item) => item !== undefined),
          (error, status) => {
            if (error) {
              alert("Something went wrong with uploading, please try again.");
            } else {
              alert(
                "Uploading for this given property has been completed. Please proceed to capture geo-points."
              );
              setPageRoute(3);
            }
          }
        );
      } else {
        setPageRoute(5);
      }
    } catch (e) {
      console.log(e);
      alert("Did not upload images. please refresh and try after a while.");
    }
  };

  return (
    <div className="background-uploadImage">
      <h2 className="text-upload-images-header">
        Please upload the property intial images
      </h2>
      <a href="whatsapp://send?phone=+917205046996">
        <div class="helpDiv">
          <img class="wpImage" src={wp} />
          <span>help</span>
        </div>
      </a>
      <p className="upload-initial-images-note">
        <strong>Note:</strong> Please capture <strong>atleast 3</strong> images
        from different angles. Also do not refresh page while capturing images.
      </p>
      <div class="center">
        <div className="collection-img">
          <div class="form-input">
            <img id="file-ip-1-preview" />
            {!mediaUrls[0] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-1">Upload Image #1</label>
                  <input
                    type="file"
                    id="file-ip-1"
                    accept="image/*"
                    capture
                    data="1"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>

          <div class="form-input">
            <img id="file-ip-2-preview" />
            {!mediaUrls[1] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-2">Upload Image #2</label>
                  <input
                    type="file"
                    id="file-ip-2"
                    accept="image/*"
                    capture
                    data="2"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>

          <div class="form-input">
            <img id="file-ip-3-preview" />
            {!mediaUrls[2] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-3">Upload Image #3</label>
                  <input
                    type="file"
                    id="file-ip-3"
                    accept="image/*"
                    capture
                    data="3"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>

          <div class="form-input">
            <img id="file-ip-4-preview" />
            {!mediaUrls[3] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-4">Upload Image #4</label>
                  <input
                    type="file"
                    id="file-ip-4"
                    accept="image/*"
                    capture
                    data="4"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>

          <div class="form-input">
            <img id="file-ip-5-preview" />

            {!mediaUrls[4] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-5">Upload Image #5</label>
                  <input
                    type="file"
                    id="file-ip-5"
                    accept="image/*"
                    capture
                    data="5"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>

          <div class="form-input">
            <img id="file-ip-6-preview" />

            {!mediaUrls[5] ? (
              loading ? (
                <center>
                  <h3>Please wait ...</h3>
                </center>
              ) : (
                <>
                  <label for="file-ip-6">Upload Image #6</label>
                  <input
                    type="file"
                    id="file-ip-6"
                    accept="image/*"
                    capture
                    data="6"
                    onChange={showPreview}
                    ref={fileInput}
                  />
                </>
              )
            ) : (
              <center>
                <h4 className="upload-text">Upload Successful</h4>
              </center>
            )}
          </div>
        </div>
      </div>
      <div className="upload-finish-button">
        <button
          className="button-start"
          disabled={isDisabled}
          onClick={UploadInitialImages}
        >
          Upload and Proceed
        </button>
      </div>
    </div>
  );
}

export default UploadInitialImages;
